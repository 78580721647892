.leftNavButton {
    justify-content: flex-start !important;
    padding-left: 0 !important;
}

.dashboardButton {
    justify-content: flex-start !important;
    margin: 16px 0 !important;
}

.fullWidthAside {
    padding: 0 !important;
}

.addButton {
    margin-top: -24px !important;
}

.spacingTop {
    margin-top: 16px !important;
}

.noPadding {
    padding: 0 !important;
}

.minH16 {
    min-height: 16px !important;
}

.article-content p {
    margin-bottom: 10px;
}
